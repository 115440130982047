<!--新增/编辑行驶证-->
<template>
  <div>
    <el-form :model="account" ref="form" label-position="right" :rules="rules">
      <el-row>
        <el-col :span="24" class="titleContent">
          <span class="title iconTitle"><i></i>开户基本信息</span>
        </el-col>
      </el-row>
      <el-row style="height:80px">
        <el-col :span="5">
          <el-form-item label="姓名">
            <div class="onlyFiled">{{driverName}}</div>
            <!-- <el-input disabled v-model.trim="driverName" /> -->
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="手机号">
           <div  class="onlyFiled">{{driverPhone}}</div> 
            <!-- <el-input disabled v-model.trim="driverPhone" /> -->
          </el-form-item>
        </el-col>
        <!-- 根据需求，这些个字段需要被隐藏-->
        <!-- <el-col :span="5" :offset="1">
          <el-form-item label="经营地址" prop="districtId">
            <area-components
              :defaultArea="dealArea"
              :tickCount="3"
              @change="dealChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="详细地址" prop="detailAddr">
            <el-input v-model="account.detailAddr" maxlength="200" />
          </el-form-item>
        </el-col> -->
        <!-- 根据需求，这些个字段需要被隐藏-->
      </el-row>
      <el-row>
        <el-col :span="24" class="titleContent" >
          <span class="title iconTitle"><i></i>银行卡信息<span
              class="desc">(请输入司机本人银行卡对应的卡号、开户行所在地、银行卡绑定手机号，错误填写将导致开户失败)</span></span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="uploader">
          <el-form-item label="银行卡图片 " prop="bankImgFrontPath">
            <div v-if="account.bankImgFrontPath" class="avatar">
              <img :src="bankImgFrontImage || account.bankImgFrontPath" />
              <span class="el-upload-list__item-delete" @click="handleBankImageRemove">
                <i class="el-icon-delete"></i>
              </span>
            </div>
            <el-upload v-else class="avatar-uploader" :action="uploadBankImage" :show-file-list="false"
              :on-success="handleBankImageSuccess" :before-upload="beforeBankImageUpload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <span>上传</span>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <el-form-item label="银行卡户名" prop="cardName">
            <el-input v-model.trim="account.cardName" maxlength="40" />
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="银行卡号" prop="cardNo">
            <el-input v-model.trim="account.cardNo" maxlength="19" />
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="银行卡绑定手机号" prop="mp">
            <el-input v-model.trim="account.mp" maxlength="11" />
          </el-form-item>
        </el-col>

        <el-col :span="5" :offset="1">
          <el-form-item label="开户行名称" prop="bankName">
            <el-select v-model.trim="selectBank" remote @change="bankChange" :remote-method="serachBank" filterable
              placeholder="请选择">
              <el-option v-for="item in serachBankList" :key="item.no" :label="item.bankName" :value="item.no">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <el-form-item label="开户行所在地" prop="cardProvId">
            <area-components :defaultArea="cardArea" :tickCount="2" @change="cardChange" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>



      </el-row>
      <!-- 根据需求，这些个字段需要被隐藏-->
      <!-- <el-row>
        <el-col :span="24" class="titleContent" style="margin-bottom: 20px">
          <span class="title iconTitle"
            >身份证信息<span class="desc"
              >(请输入司机本人的身份证签发日期、失效日期，见身份证国徽面，错误填写将导致开户失败)</span
            ></span
          >
        </el-col>
      </el-row>
      <el-row
        ><el-col :span="5">
          <el-form-item label="签发日期" prop="certBeginDateStr">
            <el-date-picker
              type="date"
              v-model="account.certBeginDateStr"
              :picker-options="signDateOptions"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              key="picker1"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item prop="certEndDateStr">
            <template slot="label">
              <span class="required">有效期限至</span>
            </template>
            <el-checkbox
              style="float: right"
              true-label="1"
              false-label="0"
              v-model="account.certValidityType"
              >长期</el-checkbox
            >
            <el-date-picker
              type="date"
              v-model="account.certEndDateStr"
              :picker-options="validateTimeOptions"
              v-show="account.certValidityType == '0'"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              key="picker2"
            >
            </el-date-picker>
            <el-input
              readonly
              v-show="account.certValidityType == '1'"
              value="长期"
            ></el-input> </el-form-item
        ></el-col>
      </el-row> -->
      <!-- 根据需求，这些个字段需要被隐藏-->
      <div class="operate">
        <el-button type="primary" @click="submitForm" :loading="submitState">提交{{ account.imgPath }}</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import AreaComponents from "@/components/form/AreaComponents";
import { navTo } from "@/utils/NavigatorUtils";
import { uploadBankImageForHuiFu } from "@/utils/UploaderUtils";
import commonApi from "@/api/commonApi";
import TripDriverApi from "@/api/TripDriverApi";
import ocrApi from "@/api/ocrApi";
import { mapMutations } from "vuex";
export default {
  components: {
    AreaComponents,
  },
  data() {
    let that = this;
    return {
      driverName: null,
      driverPhone: null,
      dealArea: [], //经营地址
      cardArea: [],
      selectBank: "",
      bankImgFrontImage: "",
      account: {
        id: "",
        driverNo: "",
        provId: "",
        areaId: "",
        districtId: "",
        detailAddr: "",
        cardName: "",
        cardNo: "",
        cardProvId: "650000",
        cardAreaId: "650100",
        bankCode: "",
        bankName: "",
        mp: "",
        certValidityType: 0,
        certBeginDateStr: "",
        certEndDateStr: "",
        bankImgFrontPath: "",
        bankImgFrontFileid: "",
      },
      uploadBankImage: uploadBankImageForHuiFu(),
      value: null,
      bankList: [],
      serachBankList: [],
      submitState: false,
      validateTimeOptions: {
        disabledDate(time) {
          return new Date(time).getTime() < Date.now();
        },
      },
      signDateOptions: {
        disabledDate(time) {
          return new Date(time).getTime() > Date.now();
        },
      },
      rules: {
        bankImgFrontPath: [
          { required: true, message: "银行卡照片不能为空", trigger: "blur" },
        ],
        districtId: [
          { required: true, message: "经营地址不能为空", trigger: "blur" },
        ],
        detailAddr: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
          { min: 2, max: 200, message: "长度应为2-200字符", trigger: "blur" },
        ],
        cardName: [
          { required: true, message: "银行卡户名不能为空", trigger: "blur" },
          { min: 2, max: 200, message: "长度应为2-40字符", trigger: "blur" },
        ],
        cardNo: [
          { required: true, message: "银行卡号不能为空", trigger: "blur" },
          {
            pattern: /^[1-9]{1}\d{14,19}$/,
            message: "银行卡号格式错误",
            trigger: "blur",
          },
        ], mp: [
          {
            required: true,
            message: "银行卡绑定手机号不能为空",
            trigger: "blur",
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "银行卡绑定手机号格式错误",
            trigger: "blur",
          },
        ],
        bankName: [
          { required: true, message: "开户行名称不能为空", trigger: "blur" },
        ],

        cardProvId: [
          { required: true, message: "开户行所在地不能为空", trigger: "blur" },
        ],
        certBeginDateStr: [
          { required: true, message: "签发日期不能为空", trigger: "blur" },
        ],
        certEndDateStr: [
          {
            validator: (rule, value, callback) => {
              if (that.account.certValidityType || value) {
                callback();
              } else {
                callback(new Error("有效期限不能为空"));
              }
            },
          },
        ],

      },
    };
  },
  created() {
    this.driverId = this.$route.params.id;
    this.loadDriver();
    this.loadBankList();
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
    }),
    handleBankImageRemove() {
      this.bankImgFrontImage = "";
      this.account.bankImgFrontPath = "";
    },
    handleBankImageSuccess(res, file) {
      let data = file.response.data;
      this.$set(this.account, "bankImgFrontImage", data.path);
      this.account.bankImgFrontPath = data.url;
      this.account.bankImgFrontFileid = data.fileId;
      this.$refs.form.validateField("bankImgFrontPath");
      this.ocrRrecognizeBankCard(data.url);
    },
    beforeBankImageUpload(file) {
      let types = ["jpg", "png"];
      let ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const isLt10M = file.size / 1024 / 1024 < 10;
      const isImage = types.includes(ext.toLowerCase());
      console.log(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 jpg、png 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isImage && isLt10M;
    },
    loadDriver() {
      TripDriverApi.queryTripDriverById({ id: this.driverId }).then((res) => {
        if (res.code == "200") {
          this.account.driverNo = res.data.no;
          this.driverName = res.data.name;
          this.driverPhone = res.data.mobile;
          this.account.cardName = res.data.name;
          this.account.mp = res.data.mobile;
          this.getAccount();
        }
      });
    },
    getTripCompanyArea() {
      this.account.cardName = this.driverName;
      if (!this.$store.state.user.companyNo) return;
      TripDriverApi.queryTripCompanyByNo(this.$store.state.user.companyNo).then(
        (res) => {
          if (res.code == "200") {
            this.dealArea = [
              res.data.provinceCode,
              res.data.cityCode,
              res.data.distinctCode,
            ];
            this.account.provId = this.dealArea[0];
            this.account.areaId = this.dealArea[1];
            this.account.districtId = this.dealArea[2];
            this.account.detailAddr = res.data.address;
          }
        }
      );
    },
    getAccount() {
      TripDriverApi.findDriverAccount(this.account.driverNo).then((resp) => {
        if (resp.code == "200") {
          this.account = resp.data;
          this.dealArea = [
            this.account.provId,
            this.account.areaId,
            this.account.districtId,
          ]; //经营地址
          this.selectBank = this.account.bankCode;
          this.cardArea = [this.account.cardProvId, this.account.cardAreaId];
        } else {
          this.cardArea = [this.account.cardProvId, this.account.cardAreaId];
          this.getTripCompanyArea();
        }
      });
    },
    submitForm() {
      if (this.account.certValidityType == 1)
        this.account.certEndDateStr = null;
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.submitState = true;
        // this.account && this.account.id != ""
        //   ? this.modifyDriverAccount()
        //   : this.saveDriverAccount();
        this.saveDriverAccount();
      });
    },
    saveDriverAccount() {
      TripDriverApi.saveDriverAccount(this.account).then((res) => {
        this.submitState = false;
        if (res.code === '200') {
          this.account = {};
          this.cancel();
          this.$successMsg(res.msg);
        } else {
          this.$errorMsg(res.msg);
        }
      });
    },
    modifyDriverAccount() {
      TripDriverApi.modifyDriverAccount(this.account).then((res) => {
        this.submitState = false;
        if (res.code === '200') {
          this.account = {};
          this.cancel();
          this.$successMsg(res.msg);
        } else {
          this.$errorMsg(res.msg);
        }
      });
    },

    cancel() {
      this.close({
        path: "/tripdriveraccout",
      });
      this.$router.push("/TripDriverList");
    },
    loadBankList() {
      commonApi.listBank({ name: "" }).then((res) => {
        if (res.code == "200") {
          this.bankList = res.data;
          this.serachBankList = res.data.slice(0, 100);
        }
      });
    },
    dealChange(val) {
      if (val.length != 3) return;
      this.account.provId = val[0];
      this.account.areaId = val[1];
      this.account.districtId = val[2];
      this.dealArea = val;
      this.$refs.form.validateField("districtId");
    },
    cardChange(val) {
      if (val.length != 2) return;
      this.account.cardProvId = val[0];
      this.account.cardAreaId = val[1];
      this.cardArea = val;
      this.$refs.form.validateField("cardProvId");
    },
    bankChange(val) {
      this.account.bankCode = val;
      this.account.bankName = this.bankList.find((p) => p.no == val).bankName;
      this.$refs.form.validateField("bankName");
    },
    serachBank(query) {
      this.serachBankList = this.bankList.filter((p) =>
        p.bankName.includes(query)
      );
      if (this.serachBankList == null || this.serachBankList.length == 0) {
        this.serachBankList = this.bankList.slice(0, 100);
      }
    },
    ocrRrecognizeBankCard(url) {
      ocrApi.recognizeBankCard({ url: url })
        .then((resp) => {
          if (resp.code === '200') {
            if (resp.data.bankName) {
              this.serachBank(resp.data.bankName);
              this.$nextTick(() => {
                let no = this.bankList.find((p) => p.bankName.includes(resp.data.bankName)).no;
                this.bankChange(no);
                this.$set(this, 'selectBank', no);
              });
            }
            this.$set(this.account, 'cardNo', resp.data.cardNo ?? this.tripDriver.cardNo);

          }
          else {
            this.$errorMsg(resp.msg);
          }
        })
        .catch((error) => {
          this.$errorMsg(error);
        });
    }
  },
};
</script>

<style lang='scss' scoped>
.titleContent {
  font-size: 18px;
  color: #4F4D46;
  font-weight: bold;
  line-height: 24px;

  .iconTitle {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    i {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #2C6AFF;
      margin-right: 8px;
      margin-bottom: -2px;
    }

    .desc {
      color: #FF4D4F;
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      padding-left: 8px;
    }
  }
}

::v-deep .el-input,
.el-select {
  width: 100%;
}

span {
  cursor: pointer;
  user-select: none;
}

.operate {
  width: 180px;
  margin: 56px auto 0 auto;
}

.uploader {
  height: 144px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  background: #fafafa;
  border: 1px dashed #dddddd;
  width: 160px;
  height: 90px;
  line-height: 70px;
  text-align: center;
}


.avatar-uploader {
  display: block;
  position: absolute;
  width: 160px;
  top: 40px;
  left: 0;

  span {
    width: 100%;
    height: 20px;
    display: inline-block;
    position: relative;
    top: -50px;
    color: #666666;
    font-size: 14px;
  }

  .el-icon-plus {
    color: #2C6AFF;
  }
}

.avatar {
  width: 160px;
  height: 90px;
  display: block;
  position: absolute;
  top: 40px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    width: 0;
    height: 0;
    right: 0;
    top: 0px;
    display: inline-block;
    margin: 0;
    border-top: 40px solid red;
    border-left: 40px solid transparent;
    cursor: pointer;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;

    i {
      color: white;
      position: absolute;
      left: -18px;
      top: -32px;
    }
  }
}
::v-deep .uploader .el-form-item__error {
  top: 134px;
  left: 0px;
  font-size: 12px;
}
.avatar:hover span {
  opacity: 1;
}

::v-deep .el-row {
  margin: 8px 32px;
}
.onlyFiled{
  width: 100%;
  display: block;
  position: absolute;
  top: 30px;
  color: #4F4D46;
  font-size: 14px;
}
::v-deep .el-form-item{
  margin-bottom: 4px;
  .el-form-item__label{
    color: #666666;
  }
}
</style>